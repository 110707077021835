import ReactDOM from "react-dom";

function Popup(props) {
  const { children, className } = props;

  const portalNode = document.getElementById("external-popup");
  // portalNode.innerHTML = ''
  // console.log(portalNode)
  className.split(" ").forEach((item) => {
    portalNode.classList.add(item);
  });

  return ReactDOM.createPortal(children, portalNode);
}

export default Popup;
