import { BarLoader, MoonLoader } from "react-spinners";

const Loader = () => {
  return (
    <div className="w-full h-[100vh] flex justify-center items-center">
      <MoonLoader color="#36d7b7" height={5} width={100} />
    </div>
  );
};

export default Loader;
