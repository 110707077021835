import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useForm } from "../../hooks/useForm";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import { formatCurrency } from "../../utils/common";
import { BACKEND_URL } from "../../utils/constant";
import NotFound from "../NotFound";
const EditDeliveryNote = () => {
  const formatMessage = useFormatMessage();
  const params = useParams();
  const history = useHistory();
  const {
    handleInputChange,
    fieldChange,
    formData,
    submitForm,
    error,
    setError,
    setFormData,
  } = useForm();
  useEffect(() => {
    axios
      .get(
        BACKEND_URL + "/sale?status=ACTIVE&included=true&sale_id=" + params.id,
        { withCredentials: true }
      )
      .then((response) => {
        const result = response.data.data[0];
        setFormData(result?.deliveryNote);
      })
      .catch((e) => {
        console.log(e.response);
      });
  }, []);
  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      ...formData,
      sale_id: params.id,
    };
    console.log(data);
    submitForm(
      "PUT",
      "/sale/deliveryNote/" + formData.delivery_note_id,
      data,
      () => {
        history.push("/dashboard/saleVoucher");
      }
    );
  };
  return (
    <div>
      <div className="sub-page-layout-1">
        <Link className="back-to-page" to="/dashboard/saleVoucher">
          <img src={backArrowIcon} />
          {formatMessage("common.back")}
        </Link>
        <div className="container">
          <h1 className="sub-page-title my-5">
            {formatMessage("common.editDeliveryNote")}
          </h1>
          <div className="form-container">
            <form
              className="form flex flex-col justify-between w-full"
              style={{ marginTop: 0 }}
              onSubmit={onSubmit}
            >
              <div className="grow">
                <textarea
                  type="text"
                  name="note"
                  onChange={handleInputChange}
                  className="x-input"
                  value={formData?.note}
                  required
                />
              </div>
              <div className="mt-2">
                {error ? (
                  <span className="w-full block text-lg text-red-500 text-right">
                    {error}
                  </span>
                ) : (
                  ""
                )}
                <div className="flex justify-end gap-5">
                  <Link to={`/dashboard/saleVoucher`} className="white-btn">
                    {formatMessage("common.cancel")}
                  </Link>
                  <button className="black-btn" disabled={!formData?.note}>
                    {formatMessage("common.finish")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditDeliveryNote;
