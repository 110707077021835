import React, { useEffect, useState } from "react";
import searchIcon from "../../img/icon-search.png";
import "../../css/common.css";
import dotsIcon from "../../img/icondots.png";
import { Link, useHistory } from "react-router-dom";
import { useFetchData } from "../../hooks/useFetchData";
import { BACKEND_URL } from "../../utils/constant";
import { Pagination } from "../../components/Pagination";
import editIcon from "../../img/icon-edit.png";
import trashCanIcon from "../../img/icon-trash.png";
import Popup from "../../components/Popup";
import axios from "axios";
import { useFormatMessage } from "../../hooks/useFormatMessage";

const VehicleComponent = ({ customer_id }) => {
  const [customer, setCustomer] = useState(null);
  const [vehiclesData, setVehiclesData] = useState([]);

  useEffect(() => {
    const url =
      BACKEND_URL +
      "/customer?included=true&status=ACTIVE&customer_id=" +
      customer_id;
    axios
      .get(url, { withCredentials: true })
      .then((response) => {
        setCustomer(response.data.data[0]);
        setVehiclesData(response.data?.data[0]?.vehicles);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [customer_id]);

  return (
    <>
      {vehiclesData?.length > 0 ? (
        vehiclesData.map((vehicle, index) => (
          <span key={vehicle.vehicle_id}>
            {vehicle.vehicle_name}
            {index !== vehiclesData.length - 1 && <br />}
          </span>
        ))
      ) : (
        <span>-</span>
      )}
    </>
  );
};

function Customer() {
  const history = useHistory();
  const [popup, setPopup] = useState({
    id: "",
    show: false,
  });
  const currentPath = history.location.pathname;
  const [isOptionOpen, setisOptionOpen] = useState(false);
  const { data, setCurrentPage, setQuery, reload, loading } = useFetchData({
    url: BACKEND_URL + "/customer",
    defaultQuery: "status=ACTIVE",
    sort: "customer_name",
  });
  const formatMessage = useFormatMessage();
  const toggleOptions = (id) => {
    const optionEl = document.getElementById(id);
    const activeOptionEl = document.querySelectorAll(".popup-options.open");
    console.log(activeOptionEl);
    if (activeOptionEl.length === 0 || activeOptionEl[0] == optionEl) {
      optionEl.classList.toggle("open");
      setisOptionOpen(optionEl.classList.contains("open"));
    }
  };

  const onSearchChange = (e) => {
    const text = e.target.value;
    setQuery({
      customer_name: text.trim(),
    });
  };

  const onCloseClick = (e) => {
    e.preventDefault();
    setPopup({
      id: "",
      show: false,
    });
  };

  const onConfirmClick = (e) => {
    e.preventDefault();
    axios
      .delete(BACKEND_URL + "/customer/" + popup.id, { withCredentials: true })
      .then((response) => {
        if (response.status === 200) {
          setPopup({
            id: "",
            show: false,
          });
          reload();
        }
      })
      .catch((e) => {
        console.log(e.response);
      });
  };

  const onDeleteClick = (e) => {
    e.preventDefault();
    const id = e.target.parentNode.id
      ? e.target.parentNode.id
      : e.target.parentNode.parentNode.id;
    setPopup({
      show: true,
      id: id,
    });
  };

  useEffect(() => {
    document
      .getElementById("external-popup")
      .classList.toggle("show", popup.show);
  }, [popup.show]);

  useEffect(() => {
    const openedEl = document.querySelector(".popup-options.open");

    const handleDocumentClick = (e) => {
      if (e.target != openedEl && !openedEl.contains(e.target)) {
        openedEl.classList.remove("open");
        setisOptionOpen(false);
      }
    };
    if (openedEl) {
      setTimeout(() => {
        document.addEventListener("click", handleDocumentClick);
      }, 100);
    }
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [isOptionOpen]);

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <div className="page-layout">
      <div className="page-header">
        <h1>{formatMessage("common.customerManagement")}</h1>
        <div className="search-bar">
          <img src={searchIcon} />
          <input className="x-input" type="search" onChange={onSearchChange} />
        </div>
        <Link to="/dashboard/customer/create" className="create-button">
          {formatMessage("common.createNew")}
        </Link>
      </div>

      <div className="table-container">
        <table className="x-table">
          <thead>
            <tr>
              <th>{formatMessage("common.no")}</th>
              <th>{formatMessage("common.name")}</th>
              <th>{formatMessage("common.customerType")}</th>
              <th>{formatMessage("common.vehicleName")}</th>
              <th>{formatMessage("common.phone")}</th>
              <th className="button-col"></th>
            </tr>
          </thead>
          <tbody>
            {data.results.map((item, i) => {
              const index = (data.page - 1) * data.limit + i + 1;
              return (
                <tr key={i} className="hover-effect">
                  <td>{index}</td>
                  <td>{item.customer_name}</td>
                  <td>{item.customer_type}</td>
                  <td>
                    <VehicleComponent customer_id={item.customer_id} />
                  </td>
                  {/* <td>
                    {item.vehicles?.length > 0 ? (
                      item.vehicles.map((vehicle, index) => (
                        <span key={vehicle.vehicle_id}>
                          {vehicle.vehicle_name}
                          {index !== item.vehicles.length - 1 && <br />}
                        </span>
                      ))
                    ) : (
                      <span>-</span>
                    )}
                  </td> */}
                  <td>{item.customer_phone_1}</td>
                  <td className="button-col">
                    <img
                      src={dotsIcon}
                      onClick={() => toggleOptions(item.customer_id)}
                    />
                    <div className="popup-options" id={item.customer_id}>
                      <Link to={`${currentPath}/${item.customer_id}/edit`}>
                        <img src={editIcon} />
                        {formatMessage("common.edit")}
                      </Link>
                      <a href="" onClick={onDeleteClick}>
                        <img src={trashCanIcon} />
                        {formatMessage("common.remove")}
                      </a>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="pagination-container">
        <Pagination data={data} setCurrentPage={setCurrentPage} />
      </div>

      <div className="popup-container"></div>

      <Popup className="popup-container">
        {popup.show && (
          <div className="popup">
            <h4 className="text-center">
              {formatMessage("common.messageConfirmation")}
            </h4>
            <p>{formatMessage("common.messageConfirmationDes")}</p>
            <div className="flex justify-between">
              <button onClick={onConfirmClick} className="black-btn">
                {formatMessage("common.proceed")}
              </button>
              <button onClick={onCloseClick} className="white-btn">
                {formatMessage("common.cancel2")}
              </button>
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
}

export default Customer;
