import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import PriceItemContainer from "../../components/PriceItemContainer";
import ProductCategoryDropdown from "../../components/ProductCategoryDropdown";
import SaleCompletePopup from "../../components/SaleCompletePopup";
import SaleItemTable from "../../components/SaleItemTable";
import { useAuth } from "../../context/AuthProvider";
import { useForm } from "../../hooks/useForm";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import { formatCurrency } from "../../utils/common";
import { BACKEND_URL } from "../../utils/constant";
import NotFound from "../NotFound";
import VehicleDorpdown from "../../components/VehicleDropdown";
import Loader from "../../components/Loader";
function CreateSale() {
  const {
    handleInputChange,
    fieldChange,
    formData,
    submitForm,
    error,
    setError,
    setFormData,
  } = useForm({
    sub_total: 0,
    discount: 0,
    total: 0,
    change: 0,
    deposit: 0,
  });
  const [saleCompleted, setSaleCompleted] = useState({
    show: false,
    id: "",
  });
  const [isLoading, setIsLoaing] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [showDeliveryNote, setShowDeliveryNote] = useState(false);
  const [deliveryNote, setDeliveryNote] = useState(null);
  const formatMessage = useFormatMessage();
  const history = useHistory();
  const params = useParams();
  const { user, setLoading } = useAuth();
  const onSubmit = (e) => {
    e.preventDefault();
    // setLoading(true);
    const data = {
      sale_type: customer.customer_type,
      customer_id: customer.customer_id,
      promotion_type: "",
      promotion_amount: formData.discount,
      total_amount: formData.sub_total,
      status: "ACTIVE",
      items: formData.sale_items,
      deposit: formData.deposit,
      change: formData.change,
      delivery: {
        vehicle_id: formData.vehicle_id,
        departure_date: formData.departure_date,
        departure_time: formData.departure_time,
        arrival_date: formData.arrival_date,
        arrival_time: formData.arrival_time,
        description: "",
        status: "ACTIVE",
      },
      delivery_note: deliveryNote,
    };
    submitForm("POST", "/sale/cascadeCreate", data, (response) => {
      // history.push('/dashboard/saleManagement')
      setSaleCompleted({
        show: true,
        id: response.data.sale_id,
      });
    });
  };

  useEffect(() => {
    setIsLoaing(true);
    axios
      .get(
        BACKEND_URL +
          "/customer?status=ACTIVE&included=true&customer_id=" +
          params.customerId,
        { withCredentials: true }
      )
      .then((response) => {
        setCustomer(response.data.data[0]);
        setIsLoaing(false);
      })
      .catch((e) => {
        console.log(e.response);
        setIsLoaing(false);
      });
  }, []);

  useEffect(() => {
    if (formData.sale_items) {
      const sub_total = formData.sale_items.reduce(
        (totalAmount, item, index) => {
          return item.amount && item.foc_status === false
            ? totalAmount + item.amount
            : totalAmount;
        },
        0
      );
      let total = formData.discount ? sub_total - formData.discount : sub_total;
      if (total < 0) {
        total = 0;
      }
      setFormData((old) => ({
        ...old,
        sub_total: sub_total,
        total: total,
      }));
    }
  }, [formData.sale_items]);

  useEffect(() => {
    if (formData.deposit <= 0) {
      let total = formData.sub_total - formData.discount;
      if (total < 0) {
        total = 0;
      }
      setFormData((old) => ({
        ...old,
        total: total,
      }));
    }

    if (formData.deposit > 0) {
      let change = 0;
      let total = formData.sub_total - formData.discount - formData.deposit;
      if (total < 0) {
        change = Math.abs(total);
        total = 0;
      }
      setFormData((old) => ({
        ...old,
        total: total,
        change: change,
      }));
    }
  }, [formData.discount, formData.deposit]);

  if (isLoading) {
    return <Loader />;
  }

  if (!customer) {
    return <NotFound />;
  }

  const handelAddDelivery = (e) => {
    e.preventDefault();
    setShowDeliveryNote(true);
  };

  return (
    <div className="sub-page-layout-1">
      <Link className="back-to-page" to="/dashboard/saleManagement">
        <img src={backArrowIcon} />
        {formatMessage("common.back")}
      </Link>
      <div className="container">
        <h1 className="sub-page-title">
          {formatMessage("common.createNewSale")}
        </h1>
        <div className="form-container">
          <form
            onSubmit={onSubmit}
            className="form flex flex-col justify-between w-full"
            style={{ marginTop: 0 }}
          >
            <div className="">
              <div className="form-info">
                <div className="grid-render">
                  <InfoItem
                    name={formatMessage("common.customerType")}
                    value={customer.customer_type}
                  />
                  <InfoItem
                    name={formatMessage("common.name")}
                    value={customer.customer_name}
                  />
                  <InfoItem
                    name={formatMessage("common.phone1")}
                    value={customer.customer_phone_1}
                  />
                  {customer.customer_phone_2 && (
                    <InfoItem
                      name={formatMessage("common.phone2")}
                      value={customer.customer_phone_2}
                    />
                  )}
                  {customer.customer_phone_3 && (
                    <InfoItem
                      name={formatMessage("common.phone3")}
                      value={customer.customer_phone_3}
                    />
                  )}
                  {customer.customer_phone_4 && (
                    <InfoItem
                      name={formatMessage("common.phone4")}
                      value={customer.customer_phone_4}
                    />
                  )}
                  <InfoItem
                    name={formatMessage("common.shopName")}
                    value={customer.shop_name}
                  />
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6rem",
                    }}
                  >
                    <label className="input-required mt-2">
                      {formatMessage("common.vehicleName")}
                    </label>
                    <div className="dd-container">
                      <select
                        type="text"
                        name="vehicle_id"
                        className="x-input dropdown"
                        value={formData.vehicle_id}
                        // value={selectedVehicleId}
                        onChange={handleInputChange}
                      >
                        <option value="">Select</option>
                        {customer.vehicles.map((item, index) => (
                          <option key={item.vehicle_id} value={item.vehicle_id}>
                            {item.vehicle_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {/* <VehicleDorpdown
                      name="vehicle_id"
                      value={formData.vehicle_id}
                      onChange={handleInputChange}
                    /> */}
                  </div>
                  {/* <InfoItem
                    name={formatMessage("common.vehicleName")}
                    value={customer.vehicle.vehicle_name}
                  /> */}
                </div>
                <div className="vdivider"></div>
                <div className="datetime-render">
                  <div className="flex flex-col gap-4">
                    <InfoItem
                      name={formatMessage("common.date")}
                      value={moment().format("DD.M.YYYY")}
                    />
                    <InfoItem
                      name={formatMessage("common.time")}
                      value={moment().format("h:MM A")}
                    />
                  </div>
                </div>
              </div>

              <div
                className="grid grid-cols-2 gap-9"
                style={{ maxWidth: "995px" }}
              >
                <div className="">
                  <label className="x-label">
                    {formatMessage("common.departureDate")}
                  </label>
                  <input
                    className="x-input"
                    type="date"
                    // required
                    name="departure_date"
                    // value={formData.departure_date}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="">
                  <label className="x-label">
                    {formatMessage("common.departureTime")}
                  </label>
                  <input
                    type="time"
                    // required
                    name="departure_time"
                    // value={formData.departure_time}
                    onChange={handleInputChange}
                    className="x-input"
                  />
                </div>
                <div className="">
                  <label className="x-label">
                    {formatMessage("common.arrivalDate")}
                  </label>
                  <input
                    type="date"
                    className="x-input"
                    name="arrival_date"
                    // required
                    // value={formData.arrival_date}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="">
                  <label className="x-label">
                    {formatMessage("common.arrivalTime")}
                  </label>
                  <input
                    type="time"
                    // required
                    name="arrival_time"
                    // value={formData.arrival_time}
                    onChange={handleInputChange}
                    className="x-input"
                  />
                </div>
              </div>

              <div className="form-divider"></div>
              <div className={`${!showDeliveryNote ? "" : "hidden"}`}>
                <button
                  className="bg-green-300 rounded-md py-1 px-2 hover:bg-green-100"
                  onClick={handelAddDelivery}
                >
                  {formatMessage("common.addDeviveryNote")}
                </button>
              </div>
              <div className={`${showDeliveryNote ? "" : "hidden"}`}>
                <h1 className="text-xl">
                  {formatMessage("common.deliveryNote")}
                </h1>
                <textarea
                  className="x-input"
                  onChange={(e) => setDeliveryNote(e.target.value)}
                ></textarea>
              </div>
              <SaleItemTable
                sale_items={formData.sale_items}
                sale_type={customer.customer_type}
                setItems={fieldChange("sale_items")}
              />

              <div className="flex flex-col mt-12 items-end mr-16">
                <div className="flex items-center justify-between gap-8 w-80">
                  <span className="block flex-grow flex-shrink-0">
                    {formatMessage("common.deposit")}
                  </span>
                  <span>-</span>
                  <input
                    className="x-input"
                    style={{ maxWidth: "206px" }}
                    name="deposit"
                    value={formData.deposit}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="flex items-center justify-between gap-8 w-80">
                  <span className="block flex-grow flex-shrink-0">
                    {formatMessage("common.subtotal")}
                  </span>
                  <span>-</span>
                  <span
                    className="block w-full p-8"
                    style={{ maxWidth: "206px" }}
                  >
                    {formatCurrency(formData.sub_total)}
                  </span>
                </div>
                <div className="flex items-center justify-between gap-8 w-80">
                  <span className="block flex-grow flex-shrink-0">
                    {formatMessage("common.discount")}
                  </span>
                  <span>-</span>
                  <input
                    className="x-input"
                    style={{ maxWidth: "206px" }}
                    name="discount"
                    value={formData.discount}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="flex items-center justify-between gap-8 w-80">
                  <span className="block flex-grow flex-shrink-0">
                    {formatMessage("common.total")}
                  </span>
                  <span>-</span>
                  <span
                    className="block w-full p-8"
                    style={{ maxWidth: "206px" }}
                  >
                    {formatCurrency(formData.total)}
                  </span>
                </div>
                <div className="flex items-center justify-between gap-8 w-80">
                  <span className="block flex-grow flex-shrink-0">
                    {formatMessage("common.change")}
                  </span>
                  <span>-</span>
                  <span
                    className="block w-full py-4 px-8"
                    style={{ maxWidth: "206px" }}
                  >
                    {formatCurrency(formData.change)}
                  </span>
                </div>
              </div>
            </div>
            <div className="mt-2">
              {error ? (
                <span className="w-full block text-lg text-red-500 text-right">
                  {error}
                </span>
              ) : (
                ""
              )}
              <div className="flex justify-end gap-5">
                <Link to={`/dashboard/saleManagement`} className="white-btn">
                  {formatMessage("common.cancel")}
                </Link>
                <button className="black-btn">
                  {formatMessage("common.create")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      {saleCompleted.show ? (
        <SaleCompletePopup
          show={saleCompleted.show}
          sale_id={saleCompleted.id}
        />
      ) : (
        ""
      )}
    </div>
  );
}

const InfoItem = ({ name, value }) => (
  <div className="flex gap-11">
    <span className="w-36">{name}</span>
    <span>-</span>
    <span className="flex-grow whitespace-nowrap">{value}</span>
  </div>
);

export default CreateSale;
