import axios from "axios";
import React, { useEffect, useState } from "react";
import { BACKEND_URL } from "../utils/constant";

function VehicleDropdown({ ...rest }) {
  const [vehicle, setvehicle] = useState([]);

  useEffect(() => {
    const url = BACKEND_URL + "/vehicle?status=ACTIVE";
    axios
      .get(url, { withCredentials: true })
      .then((response) => {
        setvehicle(response.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <div className="dd-container">
      <select type="text" value="" className="x-input dropdown" {...rest}>
        <option value="">Select</option>
        {vehicle.map((item, index) => (
          <option key={item.vehicle_id} value={item.vehicle_id}>
            {item.vehicle_name}
          </option>
        ))}
      </select>
    </div>
  );
}

export default VehicleDropdown;
