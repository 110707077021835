import axios from "axios";
import React, { useEffect, useState } from "react";
import { BACKEND_URL } from "../utils/constant";
import Select from "react-select";

function MultiVehicleDropdown({ setVehicleIds, defaultOptions }) {
  const [vehicle, setVehicle] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);

  const handleChange = (selectedOption) => {
    setSelectedValue(selectedOption);
    let ids = selectedOption.map((item, index) => item.value);
    setVehicleIds(ids);
  };

  useEffect(() => {
    const url = BACKEND_URL + "/vehicle?status=ACTIVE";
    axios
      .get(url, { withCredentials: true })
      .then((response) => {
        let res = response.data.data.map((item, index) => ({
          value: item.vehicle_id,
          label: item.vehicle_name,
        }));
        setVehicle(res);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    if (defaultOptions && vehicle.length > 0) {
      const defaultSelectedOptions = defaultOptions.map((item) => ({
        value: item.vehicle_id,
        label: item.vehicle_name,
      }));
      setSelectedValue(defaultSelectedOptions);
      let ids = defaultSelectedOptions.map((item, index) => item.value);
      setVehicleIds(ids);
    }
  }, [defaultOptions, vehicle]);

  return (
    <div className="dd-container">
      <Select
        options={vehicle}
        onChange={handleChange}
        value={selectedValue}
        name="vehicle_id"
        id="vehicle_id"
        isMulti
      ></Select>
    </div>
  );
}

export default MultiVehicleDropdown;
