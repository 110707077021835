import axios from "axios";
import React, { useEffect, useState } from "react";
import { useFormatMessage } from "../hooks/useFormatMessage";
import { BACKEND_URL, voucherCSS } from "../utils/constant";
import editIcon from "../img/icon-edit.png";
import trashCanIcon from "../img/icon-trash.png";
import dotsIcon from "../img/icondots.png";
import Popup from "./Popup";
import { Link } from "react-router-dom";
import iconPrint from "../img/icon-print.png";
import {
  formatCurrency,
  populateItemIntoVoucher,
  prepareVoucherWindow,
} from "../utils/common";
import "../css/voucher.css";
import voucherLogo1 from "../img/voucher_logo_1.png";
import voucherLogo2 from "../img/voucher_logo_2.png";
import voucherLogo3 from "../img/voucher_logo_3.png";
import voucherPhone from "../img/voucher_phone.png";
import voucherFb from "../img/voucher_fb.png";
import voucherStyle from "../css/voucher.css";
import moment from "moment";
import SaleVoucher from "./SaleVoucher";

function SaleCompletePopup({ show, sale_id }) {
  const formatMessage = useFormatMessage();
  const [saleItemData, setsaleItemData] = useState(null);
  const [saleData, setsaleData] = useState(null);
  const saleItemArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  useEffect(() => {
    if (show) {
      let url =
        BACKEND_URL + "/saleItem?included=true&sort=sort&sale_id=" + sale_id;
      // axios
      //   .get(url, { withCredentials: true })
      //   .then((response) => {
      //     const temp = response.data.data;
      //     console.log(temp);
      //     setsaleItemData(temp);
      //   })
      //   .catch((e) => {
      //     console.log(e.response);
      //   });

      url = BACKEND_URL + "/saleInvoice?included=true&sale_id=" + sale_id;
      axios
        .get(url, { withCredentials: true })
        .then((response) => {
          const temp = response.data.data[0];
          console.log(temp);
          setsaleData(temp);
          setsaleItemData(temp.saleInvoiceItems);
        })
        .catch((e) => {
          console.log(e.response);
        });

      document.getElementById("external-popup").classList.add("show");
    }
  }, [show]);

  const printVoucher = (e) => {
    e.preventDefault();
    let limit = 11;
    let printPage = Math.ceil(saleItemData.length / limit);
    let tempItem = [...saleItemData];

    var a = window.open("", "", "height=full, width=full");
    for (let i = 1; i <= printPage; i++) {
      let start = (i - 1) * limit;
      let end = i * limit;
      populateItemIntoVoucher(tempItem.slice(start, end), start + 1, i, limit);
      prepareVoucherWindow(a, i == printPage);
    }
    a.print();
    a.close();
    return;
  };

  return (
    <Popup className="popup-container show pt-8">
      <div
        className="sale-complete-popup overflow-auto relative"
        style={{ maxWidth: "1080px", maxHeight: "90%" }}
      >
        <SaleVoucher formData={saleData} saleItemData={saleItemData} />

        <span>
          <img />
        </span>
        <h4 className="text-start">{formatMessage("common.voucherDetails")}</h4>

        <div className="table-container overflow-auto">
          <table className="x-table">
            <thead>
              <tr>
                <th>{formatMessage("common.no")}</th>
                <th>{formatMessage("common.product")}</th>
                <th>{formatMessage("common.unit")}</th>
                <th>{formatMessage("common.quantity")}</th>
                <th>{formatMessage("common.price")}</th>
                <th>{formatMessage("common.amount")}</th>
                <th>{formatMessage("common.free")}</th>
                <th className="button-col"></th>
              </tr>
            </thead>
            <tbody>
              {saleItemData &&
                saleItemData.map((item, i) => {
                  return (
                    <tr key={i} className="">
                      <td>{i + 1}</td>
                      <td>{item.product.product_name}</td>
                      <td>{item.saleUnit.unit_name}</td>
                      <td>{item.quantity}</td>
                      <td>{formatCurrency(item.price)}</td>
                      <td>{formatCurrency(item.amount)}</td>
                      <td>
                        <input
                          type="checkbox"
                          defaultChecked={item.foc_status == true}
                          onClick={(e) => e.preventDefault()}
                        />
                      </td>
                      <td className="button-col">
                        <img src={dotsIcon} />
                        {/* <div className='popup-options' id={item.product_id}>
							<Link to={`${currentPath}/${item.product_id}/edit`}><img src={editIcon}/>{ formatMessage('common.edit') }</Link>
							<a href='' onClick={onDeleteClick}><img src={trashCanIcon}/>{ formatMessage('common.remove') }</a>
							</div> */}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>

        {saleData && (
          <div className="flex flex-col mt-12 items-end mr-30 gap-10">
            <div className="flex items-center justify-between gap-8 w-80">
              <span className="block w-20 flex-shrink-0">
                {formatMessage("common.deposit")}
              </span>
              <span>-</span>
              <span className="block ml-8 w-full" style={{ maxWidth: "206px" }}>
                {formatCurrency(saleData.sale.deposit)}
              </span>
            </div>
            <div className="flex items-center justify-between gap-8 w-80">
              <span className="block w-20 flex-shrink-0">
                {formatMessage("common.subtotal")}
              </span>
              <span>-</span>
              <span className="block ml-8 w-full" style={{ maxWidth: "206px" }}>
                {formatCurrency(saleData.total_amount)}
              </span>
            </div>
            <div className="flex items-center justify-between gap-8 w-80">
              <span className="block w-20 flex-shrink-0">
                {formatMessage("common.discount")}
              </span>
              <span>-</span>
              <span className="block ml-8 w-full" style={{ maxWidth: "206px" }}>
                {formatCurrency(saleData.promotion_amount)}
              </span>
            </div>
            <div className="flex items-center justify-between gap-8 w-80">
              <span className="block w-20 flex-shrink-0">
                {formatMessage("common.total")}
              </span>
              <span>-</span>
              <span className="block ml-8 w-full" style={{ maxWidth: "206px" }}>
                {saleData.sale.change <= 0
                  ? formatCurrency(
                      saleData.total_amount -
                        saleData.promotion_amount -
                        saleData.sale.deposit
                    )
                  : 0}
              </span>
            </div>
            <div className="flex items-center justify-between gap-8 w-80">
              <span className="block w-20 flex-shrink-0">
                {formatMessage("common.change")}
              </span>
              <span>-</span>
              <span className="block ml-8 w-full" style={{ maxWidth: "206px" }}>
                {formatCurrency(saleData.sale.change)}
              </span>
            </div>
          </div>
        )}

        <div className="flex justify-end mt-7 gap-5">
          <Link
            to="/dashboard/saleVoucher"
            className="white-btn flex justify-center gap-3"
          >
            Close
          </Link>
          <Link
            to="/dashboard/saleManagement"
            onClick={printVoucher}
            className="black-btn flex justify-center gap-3"
          >
            <img src={iconPrint} style={{ width: "24px", height: "24px" }} />
            Print
          </Link>
        </div>
      </div>
    </Popup>
  );
}

export default SaleCompletePopup;
