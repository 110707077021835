import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import SaleItemDetailsTable from "../../components/SaleItemDetailsTable";
import SaleItemTable from "../../components/SaleItemTable";
import UnitDropdown from "../../components/UnitsPerSKUDropdown";
import { useAuth } from "../../context/AuthProvider";
import { useForm } from "../../hooks/useForm";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import {
  formatCurrency,
  populateItemIntoVoucher,
  prepareVoucherWindow,
} from "../../utils/common";
import { BACKEND_URL, voucherCSS, voucherHTML } from "../../utils/constant";
import NotFound from "../NotFound";
import SaleVoucher from "../../components/SaleVoucher";
import Loader from "../../components/Loader";

function SaleAcceptedDetails() {
  const {
    handleInputChange,
    fieldChange,
    formData,
    submitForm,
    error,
    setError,
    setFormData,
  } = useForm({
    sub_total: 0,
    discount: 0,
    total: 0,
  });
  const [popup, setPopup] = useState({
    show: false,
    id: "",
  });
  const [customer, setCustomer] = useState(null);
  const formatMessage = useFormatMessage();
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        BACKEND_URL +
          "/saleInvoice?status=ACTIVE&included=true&sale_invoice_id=" +
          params.id,
        { withCredentials: true }
      )
      .then((response) => {
        const result = response.data.data[0];
        console.log(result);
        setCustomer(result.customer);
        setFormData({
          ...result,
          sale_items: result.saleInvoiceItems,
        });
        setPopup({
          ...popup,
          sale_id: result.sale_id,
        });
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e.response);
      });
  }, []);

  const printVoucher = (e) => {
    e.preventDefault();
    let limit = 11;
    let printPage = Math.ceil(formData.sale_items.length / limit);
    let tempItem = [...formData.sale_items];

    var a = window.open("", "", "height=full, width=full");
    for (let i = 1; i <= printPage; i++) {
      let start = (i - 1) * limit;
      let end = i * limit;
      populateItemIntoVoucher(tempItem.slice(start, end), start + 1, i, limit);
      prepareVoucherWindow(a, i == printPage);
    }
    a.print();
    a.close();
    return;
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!customer) {
    return <NotFound />;
  }

  return (
    <div className="sub-page-layout-1">
      <SaleVoucher formData={formData} />

      <Link className="back-to-page" to="/dashboard/saleVoucher">
        <img src={backArrowIcon} />
        {formatMessage("common.back")}
      </Link>
      <div className="container">
        <h1 className="sub-page-title">
          {formatMessage("common.saleDetails")}
        </h1>
        <div className="form-container">
          <form
            className="form flex flex-col justify-between w-full"
            style={{ marginTop: 0 }}
          >
            <div className="">
              <div className="form-info">
                <div className="grid-render">
                  <InfoItem
                    name={formatMessage("common.customerType")}
                    value={customer.customer_type}
                  />
                  <InfoItem
                    name={formatMessage("common.name")}
                    value={customer.customer_name}
                  />
                  <InfoItem
                    name={formatMessage("common.phone1")}
                    value={customer.customer_phone_1}
                  />
                  {/* <InfoItem name={ formatMessage('common.shopName') } value={customer.shop_name}/> */}
                  {customer.customer_phone_2 && (
                    <InfoItem
                      name={formatMessage("common.phone2")}
                      value={customer.customer_phone_2}
                    />
                  )}
                  {customer.customer_phone_3 && (
                    <InfoItem
                      name={formatMessage("common.phone3")}
                      value={customer.customer_phone_3}
                    />
                  )}
                  {customer.customer_phone_4 && (
                    <InfoItem
                      name={formatMessage("common.phone4")}
                      value={customer.customer_phone_4}
                    />
                  )}
                  <InfoItem
                    name={formatMessage("common.vehicleName")}
                    value={formData.delivery?.vehicle?.vehicle_name ?? ""}
                  />
                </div>
                <div className="vdivider"></div>
                <div className="datetime-render" style={{ maxWidth: "100%" }}>
                  <div className="flex flex-col gap-5">
                    <InfoItem
                      name={formatMessage("common.voucherNo")}
                      value={formData.sale_invoice}
                    />
                    <InfoItem
                      name={formatMessage("common.acceptedDate")}
                      value={moment().format("h:MM A")}
                    />
                  </div>
                </div>
              </div>

              <div className="x-divider"></div>
              {formData.sale?.deliveryNote?.note ? (
                <div className="mt-5">
                  <h1 className="text-lg my-3">Delivery Note</h1>
                  <textarea
                    className="x-input"
                    value={formData.sale.deliveryNote.note}
                    readOnly
                  />
                </div>
              ) : (
                ""
              )}

              <SaleItemDetailsTable
                sale_items={formData.sale_items}
                setItems={() => {}}
                sale_type=""
              />

              <div className="flex flex-col mt-12 gap-11 items-end mr-16">
                <div className="flex items-center justify-between gap-8 w-80">
                  <span className="block opacity-40 flex-shrink-0 w-20">
                    {formatMessage("common.deposit")}
                  </span>
                  <span>-</span>
                  <span
                    className="block ml-8 w-full"
                    style={{ maxWidth: "206px" }}
                  >
                    {formatCurrency(formData.sale.deposit)}
                  </span>
                </div>
                <div className="flex items-center justify-between gap-8 w-80">
                  <span className="block opacity-40 flex-shrink-0 w-20">
                    {formatMessage("common.subtotal")}
                  </span>
                  <span>-</span>
                  <span
                    className="block ml-8 w-full"
                    style={{ maxWidth: "206px" }}
                  >
                    {formatCurrency(formData.total_amount)}
                  </span>
                </div>
                <div className="flex items-center justify-between gap-8 w-80">
                  <span className="block opacity-40 flex-shrink-0 w-20">
                    {formatMessage("common.discount")}
                  </span>
                  <span>-</span>
                  <span
                    className="block ml-8 w-full"
                    style={{ maxWidth: "206px" }}
                  >
                    {formatCurrency(formData.promotion_amount)}
                  </span>
                </div>
                <div className="flex items-center justify-between gap-8 w-80">
                  <span className="block opacity-40 flex-shrink-0 w-20">
                    {formatMessage("common.total")}
                  </span>
                  <span>-</span>
                  <span
                    className="block ml-8 w-full"
                    style={{ maxWidth: "206px" }}
                  >
                    {formData.sale.change <= 0
                      ? formatCurrency(
                          formData.total_amount -
                            formData.promotion_amount -
                            formData.sale.deposit
                        )
                      : 0}
                  </span>
                </div>
                <div className="flex items-center justify-between gap-8 w-80">
                  <span className="block opacity-40 flex-shrink-0 w-20">
                    {formatMessage("common.change")}
                  </span>
                  <span>-</span>
                  <span
                    className="block ml-8 w-full"
                    style={{ maxWidth: "206px" }}
                  >
                    {formatCurrency(formData.sale.change)}
                  </span>
                </div>
              </div>
            </div>
            <div className="mt-2">
              {error ? (
                <span className="w-full block text-lg text-red-500 text-right">
                  {error}
                </span>
              ) : (
                ""
              )}
              <div className="flex justify-end gap-5">
                <Link to={`/dashboard/saleVoucher`} className="white-btn">
                  {formatMessage("common.cancel")}
                </Link>
                <button onClick={printVoucher} className="black-btn">
                  {formatMessage("common.print")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

const InfoItem = ({ name, value }) => (
  <div className="flex gap-5">
    <span className="w-36 flex-shrink-0 block whitespace-nowrap">{name}</span>
    <span className="block">-</span>
    <span className="block whitespace-nowrap">{value}</span>
  </div>
);

export default SaleAcceptedDetails;
