import React, { useEffect, useState, useRef } from "react";
import searchIcon from "../../img/icon-search.png";
import "../../css/common.css";
import dotsIcon from "../../img/icondots.png";
import { Link, useHistory } from "react-router-dom";
import { useFetchData } from "../../hooks/useFetchData";
import { BACKEND_URL } from "../../utils/constant";
import { Pagination } from "../../components/Pagination";
import editIcon from "../../img/icon-edit.png";
import trashIcon from "../../img/icon-trash.png";
import detailsIcon from "../../img/icon-details.png";
import hideIcon from "../../img/icon-hide.png";

import Popup from "../../components/Popup";
import axios from "axios";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import moment from "moment";
import { formatCurrency } from "../../utils/common";
import { useReactToPrint } from "react-to-print";
import voucherLogo from "../../img/voucher_logo_3.png";
import voucherPhone from "../../img/voucher_phone.png";
import voucherFb from "../../img/voucher_fb.png";

function SaleAccepted() {
  const history = useHistory();
  const [popup, setPopup] = useState({
    id: "",
    show: false,
  });
  const [showPrintPopUp, setShowPrintPopUp] = useState({
    id: "",
    show: false,
  });
  const currentPath = history.location.pathname;
  const [isOptionOpen, setisOptionOpen] = useState(false);
  const { data, setCurrentPage, setQuery, reload } = useFetchData({
    url: BACKEND_URL + "/saleInvoice/search",
    defaultQuery: "status=ACTIVE",
  });
  const formatMessage = useFormatMessage();

  const toggleOptions = (id) => {
    const optionEl = document.getElementById(id);
    const activeOptionEl = document.querySelectorAll(".popup-options.open");
    console.log(activeOptionEl);
    if (activeOptionEl.length === 0 || activeOptionEl[0] == optionEl) {
      optionEl.classList.toggle("open");
      setisOptionOpen(optionEl.classList.contains("open"));
    }
  };

  const onSearchChange = (e) => {
    // enter key down
    if (e.keyCode == 13) {
      const text = e.target.value;
      setQuery({
        searchValue: text.trim(),
      });
    }
  };

  const searchClose = (e) => {
    if (e.target.value.trim() === "") {
      setQuery({
        searchValue: "",
      });
    }
  };

  const onCloseClick = (e) => {
    e.preventDefault();
    setPopup({
      id: "",
      show: false,
    });
  };

  const onConfirmClick = (e) => {
    e.preventDefault();
    popup.callback();
  };

  const onDeleteClick = (e) => {
    e.preventDefault();
    const id = e.target.parentNode.id;
    const callback = () => {
      axios
        .delete(BACKEND_URL + "/saleInvoice/" + id, {
          withCredentials: true,
        })
        .then((response) => {
          if (response.status === 200) {
            setPopup({
              id: "",
              show: false,
              callback: () => {},
            });
            reload();
          }
        })
        .catch((e) => {
          console.log(e.response);
        });
    };
    setPopup({
      show: true,
      id: id,
      callback,
    });
  };

  const handleDeliveryNoteDelete = (e) => {
    e.preventDefault();
    const sale_id = e.target.id;

    const callback = () =>
      axios
        .delete(`${BACKEND_URL}/sale/deliveryNote/${sale_id}`, {
          withCredentials: true,
        })
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            setPopup({
              id: "",
              show: false,
              callback: () => {},
            });
            reload();
          }
        })
        .catch((error) => {
          console.error(error.response);
        });

    setPopup({
      show: true,
      id: sale_id,
      callback,
    });
  };

  const onWriteOffClick = (e) => {
    e.preventDefault();
    const id = e.target.parentNode.id;
    const callback = () => {
      axios
        .put(
          BACKEND_URL + "/saleInvoice/" + id,
          { status: "INACTIVE" },
          { withCredentials: true }
        )
        .then((response) => {
          if (response.status === 200) {
            setPopup({
              id: "",
              show: false,
              callback: () => {},
            });
            reload();
          }
        })
        .catch((e) => {
          console.log(e.response);
        });
    };
    setPopup({
      show: true,
      id: id,
      callback,
    });
  };

  const printDeliveryNote = (e) => {
    e.preventDefault();
    const sale_id = e.target.id;

    setShowPrintPopUp({ id: sale_id, show: true });
  };

  useEffect(() => {
    document
      .getElementById("external-popup")
      .classList.toggle("show", popup.show);
  }, [popup.show]);

  useEffect(() => {
    const openedEl = document.querySelector(".popup-options.open");

    const handleDocumentClick = (e) => {
      if (e.target != openedEl && !openedEl.contains(e.target)) {
        openedEl.classList.remove("open");
        setisOptionOpen(false);
      }
    };
    if (openedEl) {
      setTimeout(() => {
        document.addEventListener("click", handleDocumentClick);
      }, 100);
    }
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [isOptionOpen]);

  const goToPayment = (sale_id, sale_invoice_id) => (e) => {
    e.preventDefault();
    console.log({ sale_id });
    const url = BACKEND_URL + "/paymentReceive/getRemainingAmountBySaleId";
    axios
      .post(
        `${url}`,
        { sale_id },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        const remaining = response.data.data.remaining;
        console.log({ remaining });
        if (remaining > 0) {
          history.push(`/dashboard/saleVoucher/${sale_invoice_id}/edit`);
        }
      })
      .catch((e) => {
        console.log(e.response);
      });
  };

  return (
    <div className="page-layout">
      <div className="page-header">
        <h1>{formatMessage("common.saleAccepted")}</h1>
        <div className="search-bar">
          <img src={searchIcon} />
          <input
            className="x-input"
            type="search"
            onKeyDown={onSearchChange}
            onInput={searchClose}
          />
        </div>
      </div>

      <div className="table-container">
        <table className="x-table">
          <thead>
            <tr>
              <th>{formatMessage("common.no")}</th>
              <th>{formatMessage("common.voucherNo")}</th>
              <th>{formatMessage("common.customerName")}</th>
              <th>{formatMessage("common.amount")}</th>
              <th>{formatMessage("common.remainAmount")}</th>
              <th>{formatMessage("common.acceptedDate")}</th>
              <th
                className="button-col flex-shrink-1"
                style={{ maxWidth: "180px" }}
              ></th>
            </tr>
          </thead>
          <tbody>
            {data.results.map((item, i) => {
              const index = (data.page - 1) * data.limit + i + 1;
              return (
                <tr key={i} className="hover-effect">
                  <td>{index}</td>
                  <td>{item.sale_invoice}</td>
                  <td>{item.customer.customer_name}</td>
                  <td>{formatCurrency(item.total_amount)}</td>
                  <td>{formatCurrency(item.remaining)}</td>
                  <td>
                    {item.due_date &&
                      moment(item.due_date).format("DD.MM.YYYY")}
                  </td>
                  <td
                    className="button-col"
                    style={{
                      maxWidth: "180px",
                      justifyContent: "space-between",
                      gap: "10px",
                    }}
                  >
                    <Link
                      className="details"
                      to={`/dashboard/saleVoucher/${item.sale_invoice_id}/details`}
                    >
                      {formatMessage("common.details")}
                    </Link>
                    <img
                      src={dotsIcon}
                      onClick={() => toggleOptions(item.sale_invoice_id)}
                    />
                    <div className="popup-options" id={item.sale_invoice_id}>
                      <Link
                        to={``}
                        onClick={goToPayment(
                          item.sale_id,
                          item.sale_invoice_id
                        )}
                      >
                        <img src={detailsIcon} />
                        {formatMessage("common.makePayment")}
                      </Link>
                      <a href="" onClick={onDeleteClick}>
                        <img src={trashIcon} />
                        {formatMessage("common.remove")}
                      </a>
                      <Link to={`#`} onClick={onWriteOffClick}>
                        <img src={editIcon} />
                        {formatMessage("common.writeOff")}
                      </Link>
                      <Link to={`/dashboard/deliveryNote/${item.sale_id}/edit`}>
                        <img src={editIcon} />
                        {formatMessage("common.editDeliveryNote")}
                      </Link>

                      <a
                        href=""
                        onClick={handleDeliveryNoteDelete}
                        id={item.sale_id}
                      >
                        <img src={trashIcon} />
                        {formatMessage("common.deleteDeliveryNote")}
                      </a>
                      <a href="" onClick={printDeliveryNote} id={item.sale_id}>
                        <img src={detailsIcon} />
                        {formatMessage("common.printDeliveryNote")}
                      </a>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="pagination-container">
        <Pagination data={data} setCurrentPage={setCurrentPage} />
      </div>

      <div className="popup-container"></div>

      <Popup className="popup-container">
        {popup.show && (
          <div className="popup">
            <h4 className="text-center">
              {formatMessage("common.messageConfirmation")}
            </h4>
            <p>{formatMessage("common.messageConfirmationDes")}</p>
            <div className="flex justify-between">
              <button onClick={onConfirmClick} className="black-btn">
                {formatMessage("common.proceed")}
              </button>
              <button onClick={onCloseClick} className="white-btn">
                {formatMessage("common.cancel2")}
              </button>
            </div>
          </div>
        )}
      </Popup>
      {showPrintPopUp.show && (
        <PrintPopup
          sale_id={showPrintPopUp.id}
          setShowPrintPopUp={setShowPrintPopUp}
        />
      )}
    </div>
  );
}

const PrintPopup = ({ sale_id, setShowPrintPopUp }) => {
  const [note, setNote] = useState(null);
  useEffect(() => {
    axios
      .get(
        BACKEND_URL + "/sale?status=ACTIVE&included=true&sale_id=" + sale_id,
        { withCredentials: true }
      )
      .then((response) => {
        const result = response.data.data[0];
        setNote(result.deliveryNote?.note);
      })
      .catch((e) => {
        console.log(e.response);
      });
  }, [sale_id]);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div className="fixed pin z-50 overflow-auto bg-smoke-light flex flex-col	 right-1/3 top-10 bg-slate-100	rounded-md p-5 ">
      <div className="overflow-auto">
        <div className="relative w-96 h-auto bg-white p-2">
          <div ref={componentRef} className="px-5">
            <div className="pop-up">
              <h1 className="text-sm font-bold p-5 text-center">
                ဦးမျိုးမြင့်အောင် + ဒေါ်မို့မို့ခိုင်
              </h1>
              <div className="mb-1 flex justify-end">
                <img src={voucherLogo} width={80} />
              </div>

              <div className="text-xs font-bold text-center my-2">
                ဆိပ်ခွန် ၊ ရွှေဘို
              </div>
              <div className="flex place-items-start flex-col my-2">
                <div className="flex justify-center">
                  {/* <img className="" src={voucherPhone} width={15} /> */}
                  <div className="text-[15px] text-slate-400">
                    09-2100373, 09-942909968
                  </div>
                </div>
                <div className="flex justify-center">
                  {/* <img className="" src={voucherFb} height={10} /> */}
                  <div className="text-[12px] text-slate-400">
                    https://www.facebook.com/sinminseikkhunlongyi
                  </div>
                </div>
              </div>
              <div className="border-2 border-slate-600	 p-5">
                <p className="text-md">{note}</p>
              </div>
            </div>
          </div>
          <img
            className="absolute top-1.5 right-4 cursor-pointer w-6 h-auto"
            src={hideIcon}
            onClick={() => setShowPrintPopUp({ sale_id: "null", show: false })}
          />
        </div>
        <div className="flex justify-between my-2">
          <button
            className="border-2 border-black rounded-md bg-slate-200 text-slate-400 py-1 px-3"
            onClick={() => setShowPrintPopUp({ sale_id: "null", show: false })}
          >
            Cannel
          </button>
          <button
            className="border-2 border-black rounded-md bg-slate-800 text-white py-1 px-5"
            onClick={handlePrint}
          >
            Print
          </button>
        </div>
      </div>
      {/* <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          width: "21cm",
          padding: "2rem",
          justifyContent: "center",
          // marginTop: "50px",
        }}
      >
       
      </div> */}
      {/* <img
        className="absolute top-7 right-8 cursor-pointer w-3 h-auto"
        src={crossIcon}
        onClick={() => setPopup(null)}
      /> */}
    </div>
  );
};

export default SaleAccepted;
