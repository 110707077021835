import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import VehicleDropdown from "../../components/VehicleDropdown";
import { useForm } from "../../hooks/useForm";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import { BACKEND_URL } from "../../utils/constant";
import { useAuth } from "../../context/AuthProvider";
import MultiVehicleDropdown from "../../components/MultiVehicleDropdown";

function EditCustomer() {
  const { setLoading } = useAuth();
  const { handleInputChange, formData, setFormData, error, submitForm } =
    useForm();
  const history = useHistory();
  const params = useParams();
  const formatMessage = useFormatMessage();
  const [vehicle_ids, setVehicleIds] = useState([]);
  const [xData, setxData] = useState({});
  const [address, setAddress] = useState({
    country: [],
    division: [],
    city: [],
    township: [],
  });

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      ...formData,
      status: "ACTIVE",
      vehicle_ids: vehicle_ids,
    };
    submitForm("PUT", "/customer/" + params.id, data, () => {
      history.push("/dashboard/customer");
    });
  };

  useEffect(() => {
    const url =
      BACKEND_URL +
      "/customer?included=true&status=ACTIVE&customer_id=" +
      params.id;
    axios
      .get(url, { withCredentials: true })
      .then((response) => {
        const temp = response.data.data[0];
        setFormData({
          customer_type: temp.customer_type,
          customer_name: temp.customer_name,
          shop_name: temp.shop_name,
          customer_phone_1: temp.customer_phone_1,
          customer_phone_2: temp.customer_phone_2,
          customer_phone_3: temp.customer_phone_3,
          customer_phone_4: temp.customer_phone_4,
          vehicle_id: temp.vehicle_id,
          country_id: temp.country_id,
          city_id: temp.city_id,
          division_id: temp.division_id,
          township_id: temp.township_id,
          address: temp.address,
          description: temp.description,
          vehicles: temp.vehicles,
        });
        setxData(temp);
      })
      .catch((e) => {
        console.log(e.response);
      });
  }, []);

  useEffect(() => {
    axios
      .get(BACKEND_URL + "/address?name=division&id=" + formData.country_id, {
        withCredentials: true,
      })
      .then((response) => {
        setAddress((old) => ({
          ...old,
          division: response.data.data,
        }));
        if (!formData.country_id) {
          setFormData((old) => ({
            ...old,
            division_id:
              old.division_id === formData.division_id ? undefined : "",
            // city_id:  old.city_id === formData.city_id ? undefined : '',
            // township_id:  old.township_id === formData.township_id ? undefined : ''
          }));
        }
      });
  }, [formData.country_id]);

  useEffect(() => {
    axios
      .get(BACKEND_URL + "/address?name=city&id=" + formData.division_id, {
        withCredentials: true,
      })
      .then((response) => {
        setAddress((old) => ({
          ...old,
          city: response.data.data,
        }));
        if (
          !formData.division_id ||
          formData.division_id !== xData.division_id
        ) {
          setFormData((old) => ({
            ...old,
            city_id: old.city_id === formData.city_id ? undefined : "",
            township_id:
              old.township_id === formData.township_id ? undefined : "",
          }));
        }
      });
  }, [formData.division_id]);

  useEffect(() => {
    axios
      .get(BACKEND_URL + "/address?name=township&id=" + formData.city_id, {
        withCredentials: true,
      })
      .then((response) => {
        setAddress((old) => ({
          ...old,
          township: response.data.data,
        }));

        setFormData((old) => ({
          ...old,
          // township_id:  old.township_id === formData.township_id ? undefined : ''
        }));
      });
  }, [formData.city_id]);

  return (
    <div className="sub-page-layout-1">
      <Link className="back-to-page" to="/dashboard/customer">
        <img src={backArrowIcon} />
        {formatMessage("common.back")}
      </Link>
      <div className="container">
        <h1 className="sub-page-title">{formatMessage("common.customer")}</h1>
        <div className="form-container">
          <form
            onSubmit={onSubmit}
            className="form flex flex-col justify-between w-full"
          >
            <div className="pt-5">
              <h2 className="sub-title mb-8">
                {formatMessage("common.customerDetails")}
              </h2>

              <div
                className="grid grid-cols-2 gap-9"
                style={{ maxWidth: "995px" }}
              >
                <div className="grow">
                  <label className="x-label input-required">
                    {formatMessage("common.customerType")}
                  </label>
                  <div className="dd-container">
                    <select
                      type="text"
                      required
                      name="customer_type"
                      onChange={handleInputChange}
                      value={
                        formData.customer_type ? formData.customer_type : ""
                      }
                      className="x-input dropdown"
                    >
                      <option value="">Select</option>
                      <option value="wholesale">Wholesale</option>
                      <option value="retail">Retail</option>
                    </select>
                  </div>
                </div>

                <div className="grow">
                  <label className="x-label input-required">
                    {formatMessage("common.name")}
                  </label>
                  <input
                    type="text"
                    required
                    name="customer_name"
                    value={formData.customer_name ? formData.customer_name : ""}
                    onChange={handleInputChange}
                    className="x-input"
                  />
                </div>

                <div className="grow">
                  <label className="x-label input-required">
                    {formatMessage("common.phone1")}
                  </label>
                  <input
                    type="text"
                    required
                    name="customer_phone_1"
                    value={
                      formData.customer_phone_1 ? formData.customer_phone_1 : ""
                    }
                    onChange={handleInputChange}
                    className="x-input"
                  />
                </div>

                <div className="grow">
                  <label className="x-label">
                    {formatMessage("common.phone2")}
                  </label>
                  <input
                    type="text"
                    name="customer_phone_2"
                    value={
                      formData.customer_phone_2 ? formData.customer_phone_2 : ""
                    }
                    onChange={handleInputChange}
                    className="x-input"
                  />
                </div>
                <div className="grow">
                  <label className="x-label">
                    {formatMessage("common.phone3")}
                  </label>
                  <input
                    type="text"
                    name="customer_phone_3"
                    value={
                      formData.customer_phone_3 ? formData.customer_phone_3 : ""
                    }
                    onChange={handleInputChange}
                    className="x-input"
                  />
                </div>

                <div className="grow">
                  <label className="x-label">
                    {formatMessage("common.phone4")}
                  </label>
                  <input
                    type="text"
                    name="customer_phone_4"
                    value={
                      formData.customer_phone_4 ? formData.customer_phone_4 : ""
                    }
                    onChange={handleInputChange}
                    className="x-input"
                  />
                </div>

                <div className="grow">
                  <label className="x-label">
                    {formatMessage("common.shopName")}
                  </label>
                  <input
                    type="text"
                    value={formData.shop_name ? formData.shop_name : ""}
                    // required
                    name="shop_name"
                    onChange={handleInputChange}
                    className="x-input"
                  />
                </div>

                <div className="grow">
                  <label className="x-label input-required">
                    {formatMessage("common.vehicleName")}
                  </label>
                  {/* <VehicleDropdown 
                                required
                                name='vehicle_id' 
                                value={formData.vehicle_id ? formData.vehicle_id : ''}
                                onChange={handleInputChange}
                                /> */}
                  <MultiVehicleDropdown
                    setVehicleIds={setVehicleIds}
                    defaultOptions={formData.vehicles}
                  />
                </div>
              </div>

              <div className="form-divider"></div>

              <h2 className="sub-title mb-8">
                {formatMessage("common.otherInfo")}
              </h2>

              <div
                className="grid grid-cols-2 gap-9"
                style={{ maxWidth: "995px" }}
              >
                <div className="grow">
                  <label className="x-label input-required">
                    {formatMessage("common.country")}
                  </label>
                  <div className="dd-container">
                    <select
                      type="text"
                      required
                      name="country_id"
                      onChange={handleInputChange}
                      value={formData.country_id ? formData.country_id : ""}
                      className="x-input dropdown"
                    >
                      <option value="">Select</option>
                      <option value="150">Myanmar</option>
                    </select>
                  </div>
                </div>
                <div className="grow">
                  <label className="x-label input-required">
                    {formatMessage("common.division")}
                  </label>
                  <div className="dd-container">
                    <select
                      type="text"
                      required
                      name="division_id"
                      onChange={handleInputChange}
                      value={formData.division_id ? formData.division_id : ""}
                      className="x-input dropdown"
                    >
                      <option value="">Select</option>
                      {address.division.map((item, index) => (
                        <option key={index} value={item.division_id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="grow">
                  <label className="x-label input-required">
                    {formatMessage("common.city")}
                  </label>
                  <div className="dd-container">
                    <select
                      type="text"
                      name="city_id"
                      required
                      onChange={handleInputChange}
                      value={formData.city_id ? formData.city_id : ""}
                      className="x-input dropdown"
                    >
                      <option value="">Select</option>
                      {address.city.map((item, index) => (
                        <option key={index} value={item.city_id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="grow">
                  <label className="x-label input-required">
                    {formatMessage("common.township")}
                  </label>
                  <div className="dd-container">
                    <select
                      required
                      type="text"
                      name="township_id"
                      onChange={handleInputChange}
                      value={formData.township_id ? formData.township_id : ""}
                      className="x-input dropdown"
                    >
                      <option value="">Select</option>
                      {address.township.map((item, index) => (
                        <option key={index} value={item.township_id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="grow">
                  <label className="x-label input-required">
                    {formatMessage("common.address")}
                  </label>
                  <input
                    type="text"
                    required
                    name="address"
                    onChange={handleInputChange}
                    value={formData.address ? formData.address : ""}
                    className="x-input"
                  />
                </div>

                <div className="grow">
                  <label className="x-label">
                    {formatMessage("common.description")}
                  </label>
                  <input
                    type="text"
                    name="description"
                    value={formData.description ? formData.description : ""}
                    onChange={handleInputChange}
                    className="x-input"
                  />
                </div>
              </div>
            </div>
            <div className="mt-8">
              {error ? (
                <span className="w-full block text-lg text-red-500 text-right">
                  {error}
                </span>
              ) : (
                ""
              )}
              <div className="flex justify-end gap-5">
                <Link to={`/dashboard/customer`} className="white-btn">
                  {formatMessage("common.cancel")}
                </Link>
                <button className="black-btn">
                  {formatMessage("common.finish")}
                </button>
              </div>
            </div>
          </form>

          <div className="side-card-container">
            <div className="side-card">
              <div className="side-card-list">
                <span className="label">Created by</span>
                <span className="text">
                  {xData.created_by_user ? xData.created_by_user.name : ""}
                </span>
              </div>
              <div className="side-card-list">
                <span className="label">Created on</span>
                <span className="text">
                  {xData ? moment(xData.created_date).format("DD.MM.YYYY") : ""}
                </span>
              </div>
              <div className="side-card-list">
                <span className="label">Updated by</span>
                <span className="text">
                  {xData.updated_by_user ? xData.updated_by_user.name : "-"}
                </span>
              </div>
              <div className="side-card-list">
                <span className="label">Updated on</span>
                <span className="text">
                  {xData.updated_date
                    ? moment(xData.updated_date).format("DD.MM.YYYY")
                    : "-"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditCustomer;
