import React, { useEffect, useState } from "react";
import searchIcon from "../../img/icon-search.png";
import "../../css/common.css";
import { Link, useHistory } from "react-router-dom";
import { useFetchData } from "../../hooks/useFetchData";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import plusIcon from "../../img/icon-plus.png";
import "../../css/sale.css";
import axios from "axios";
import { BACKEND_URL } from "../../utils/constant";
import Popup from "../../components/Popup";

function SaleHome() {
  const history = useHistory();
  const [data, setData] = useState(null);
  const [walkinCustomer, setWalkinCustomer] = useState(null);
  const formatMessage = useFormatMessage();
  const [popup, setPopup] = useState({
    id: "",
    show: false,
  });
  const onSearchClick = (e) => {
    e.preventDefault();
    const text = document.getElementById("search-customer-input").value;
    const url = BACKEND_URL + "/customer/searchByNameAndShop";
    if (text) {
      const payload = {
        customer_name: text,
        shop_name: text,
      };
      axios
        .post(url, payload, { withCredentials: true })
        .then((response) => {
          console.log(response.data.data);
          setData(response.data.data);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setPopup({
        id: "",
        show: true,
      });
    }
  };

  useEffect(() => {
    const fetchWalkinCustomer = () => {
      axios
        .get(
          BACKEND_URL +
            "/customer?status=ACTIVE&included=true&created_by=Super Admin",
          { withCredentials: true }
        )
        .then((response) => {
          const result = response.data.data[0];
          setWalkinCustomer(result);
        })
        .catch((e) => {
          console.log(e.response);
        });
    };
    fetchWalkinCustomer();
  }, []);

  return (
    <div className="page-layout">
      <div className="page-header ml-7">
        <h1>{formatMessage("common.sale")}</h1>
      </div>

      <form onSubmit={onSearchClick} className="search-container">
        <div className="search-bar">
          <img src={searchIcon} />
          <input className="x-input" type="search" id="search-customer-input" />
        </div>
        <button type="submit" className="create-button" onClick={onSearchClick}>
          {formatMessage("common.search")}
        </button>
        <Link
          type="submit"
          to={
            "/dashboard/saleManagement/" +
            walkinCustomer?.customer_id +
            "/createSale"
          }
          className="bg-green-500 rounded-md w-36 px-8 py-3 text-white text-center"
        >
          Quick Sale
        </Link>
        <Link
          className="add-customer"
          to="/dashboard/saleManagement/createCustomer"
        >
          <img src={plusIcon} />
        </Link>
      </form>

      {data && (
        <div className="table-container">
          <table className="x-table">
            <thead>
              <tr>
                <th>{formatMessage("common.no")}</th>
                <th>{formatMessage("common.name")}</th>
                <th>{formatMessage("common.customerType")}</th>
                <th>{formatMessage("common.vehicleName")}</th>
                <th>{formatMessage("common.shopName")}</th>
                <th>{formatMessage("common.city")}</th>
                <th>{formatMessage("common.phone")}</th>
                <th className="button-col" style={{ maxWidth: "115px" }}></th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, i) => {
                return (
                  <tr key={i} className="">
                    <td>{i + 1}</td>
                    <td>{item.customer_name}</td>
                    <td>{item.customer_type}</td>
                    {/* <td>{item.vehicle.vehicle_name}</td> */}
                    <td>
                      {item.vehicles?.length > 0 ? (
                        item.vehicles.map((vehicle, index) => (
                          <span key={vehicle.vehicle_id}>
                            {vehicle.vehicle_name}
                            {index !== item.vehicles.length - 1 && <br />}
                          </span>
                        ))
                      ) : (
                        <span>-</span>
                      )}
                    </td>
                    <td>{item.shop_name}</td>
                    <td>{item.city.name}</td>
                    <td>{item.customer_phone_1}</td>
                    <td className="button-col" style={{ maxWidth: "115px" }}>
                      <Link
                        to={
                          "/dashboard/saleManagement/" +
                          item.customer_id +
                          "/createSale"
                        }
                        className="table-btn-style-1"
                      >
                        Voucher
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      {popup.show && (
        <div className="fixed pin z-50 overflow-auto bg-smoke-light flex flex-col	 right-1/3 top-10 bg-slate-100	rounded-md items-center px-6 py-10 gap-3">
          <h4 className="text-center text-lg font-bold">
            Please Enter Customer Name
          </h4>
          <div className="flex justify-between">
            <button
              className="border-2 border-black rounded-md bg-slate-200 text-slate-400 py-1 px-3"
              onClick={() => setPopup({ id: "", show: false })}
            >
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default SaleHome;
