import { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import "@formatjs/intl-numberformat/polyfill-force";
import "@formatjs/intl-numberformat/locale-data/my";
import "@formatjs/intl-numberformat/locale-data/en";
import "@formatjs/intl-datetimeformat/polyfill-force";
import "@formatjs/intl-datetimeformat/locale-data/my";
import "@formatjs/intl-datetimeformat/locale-data/en";
import "@formatjs/intl-relativetimeformat/polyfill-force";
import "@formatjs/intl-relativetimeformat/locale-data/my";
import "@formatjs/intl-relativetimeformat/locale-data/en";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useAuth } from "./context/AuthProvider";
import PrivateRoute from "./context/PrivateRoute";
import PublicRoute from "./context/PublicRoute";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import messages from "./utils/messages.json";
import flatten from "flat";
import SaleAccepted from "./pages/SaleAccepted/SaleAccepted";
import ProductCategory from "./pages/ProductCategory/ProductCategory";
import Product from "./pages/Product/Product";
import UnitConversion from "./pages/UnitConversion/UnitConversion";
import User from "./pages/User/User";
import Report from "./pages/Report/Report";
import CreateProductCategory from "./pages/ProductCategory/CreateProductCategory";
import EditProductCategory from "./pages/ProductCategory/EditProductCategory";
import EditProduct from "./pages/Product/EditProduct";
import CreateProduct from "./pages/Product/CreateProduct";
import Unit from "./pages/Unit/Unit";
import CreateUnit from "./pages/Unit/CreateUnit";
import EditUnit from "./pages/Unit/EditUnit";
import CreateUnitConversion from "./pages/UnitConversion/CreateUnitConversion";
import EditUnitConversion from "./pages/UnitConversion/EditUnitConversion";
import CreateUser from "./pages/User/CreateUser";
import EditUser from "./pages/User/EditUser";
import Transport from "./pages/Transport/Transport";
import CreateTransport from "./pages/Transport/CreateTransport";
import EditTransport from "./pages/Transport/EditTransport";
import Customer from "./pages/Customer/Customer";
import EditCustomer from "./pages/Customer/EditCustomer";
import CreateCustomer from "./pages/Customer/CreateCustomer";
import SaleHome from "./pages/Sale/SaleHome";
import SaleCreateCustomer from "./pages/Sale/CreateCustomer";
import CreateSale from "./pages/Sale/CreateSale";
import NotFound from "./pages/NotFound";
import SaleReturn from "./pages/SaleReturn/SaleReturn";
import CreateSaleReturn from "./pages/SaleReturn/CreateSaleReturn";
import SaleReturnDetails from "./pages/SaleReturn/SaleReturnDetails";
import EditSaleAccepted from "./pages/SaleAccepted/EditSaleAccepted";
import axios from "axios";
import { BACKEND_URL } from "./utils/constant";
import SaleTransaction from "./pages/SaleTransaction/SaleTransaction";
import SaleTransactionDetails from "./pages/SaleTransaction/SaleTransactionDetails";
import WeeklySaleDues from "./pages/Home/WeeklySaleDues";
import SaleAcceptedDetails from "./pages/SaleAccepted/SaleAcceptedDetails";
import WholesaleReportByDate from "./pages/Report/WholesaleReportByDate";
import WholesaleReportByYear from "./pages/Report/WholessaleReportByYear";
import WholesaleReportByCNDate from "./pages/Report/WholesaleReportByCNDate";
import WholesaleReportByCNYear from "./pages/Report/WholesaleReportByCNYear";
import ReceivableReportByDate from "./pages/Report/ReceivableReportByDate";
import ReceivableReportByYear from "./pages/Report/ReceivableReportByYear";
import ReceivableReportByCNDate from "./pages/Report/ReceivableReportByCNDate";
import VehicleDetailReport from "./pages/Report/VehicleDetailReport";
import DistributionReportByDate from "./pages/Report/DistributionReportByDate";
import DistributionReportByYear from "./pages/Report/DistributionReportByYear";
import ReceivableReportByDateAndCity from "./pages/Report/ReceivableReportByDateAndCity";
import LoadingPopup from "./components/LoadingPopup";
import WholesaleReportByYearAndProduct from "./pages/Report/WholesaleReportByYearAndProduct";
import EditDeliveryNote from "./pages/SaleAccepted/EditDeliveryNote";

function App() {
  axios.defaults.headers.common["Authorization"] =
    "Bearer " + sessionStorage.getItem("token");

  const { user, checkUser, lang, loading } = useAuth();

  return (
    <IntlProvider
      locale={lang}
      messages={flatten(messages[lang])}
      defaultLocale="en"
    >
      <Router>
        <Switch>
          <PublicRoute exact path="/" component={Login} />

          <Route path="/dashboard">
            <PrivateRoute
              exact
              path="/dashboard/home"
              component={Dashboard}
              menu="home"
            />
            <PrivateRoute
              exact
              path="/dashboard/weeklySaleDues"
              component={WeeklySaleDues}
              menu="home"
            />

            <PrivateRoute
              exact
              path="/dashboard/saleManagement"
              menu="အရောင်းအဝယ် (wholesale)"
              action="read"
              component={SaleHome}
            />
            <PrivateRoute
              path="/dashboard/saleManagement/createCustomer"
              menu="ဝယ်ယူသူ"
              action="create"
              component={SaleCreateCustomer}
            />
            <PrivateRoute
              path="/dashboard/saleManagement/:customerId/createSale"
              component={CreateSale}
              menu="အရောင်းအဝယ် (wholesale)"
              action="create"
            />

            <PrivateRoute
              exact
              path="/dashboard/saleReturn"
              component={SaleReturn}
              menu="အရောင်းပြန်ပေး (wholesale)"
              action="read"
            />
            <PrivateRoute
              path="/dashboard/saleReturn/create"
              component={CreateSaleReturn}
              menu="အရောင်းပြန်ပေး (wholesale)"
              action="create"
            />
            <PrivateRoute
              path="/dashboard/saleReturn/:id/view"
              component={SaleReturnDetails}
              menu="အရောင်းပြန်ပေး (wholesale)"
              action="edit"
            />

            <PrivateRoute
              exact
              path="/dashboard/saleVoucher"
              component={SaleAccepted}
              menu="အရောင်းလက်ခံ (wholesale)"
              action="read"
            />
            <PrivateRoute
              path="/dashboard/saleVoucher/:id/edit"
              component={EditSaleAccepted}
              menu="အရောင်းလက်ခံ (wholesale)"
              action="create"
            />
            <PrivateRoute
              path="/dashboard/saleVoucher/:id/details"
              component={SaleAcceptedDetails}
              menu="အရောင်းလက်ခံ (wholesale)"
              action="read"
            />
            <PrivateRoute
              path="/dashboard/deliveryNote/:id/edit"
              component={EditDeliveryNote}
              menu="အရောင်းလက်ခံ (wholesale)"
              action="read"
            />

            <PrivateRoute
              exact
              path="/dashboard/saleTransaction"
              component={SaleTransaction}
              menu="အရောင်းအဝယ် (wholesale)"
              action="read"
            />
            <PrivateRoute
              path="/dashboard/saleTransaction/:id/view"
              component={SaleTransactionDetails}
              menu="အရောင်းလက်ခံ (wholesale)"
              action="read"
            />

            <PrivateRoute
              exact
              path="/dashboard/customer"
              component={Customer}
              menu="ဝယ်ယူသူ"
              action="read"
            />
            <PrivateRoute
              path="/dashboard/customer/create"
              component={CreateCustomer}
              menu="ဝယ်ယူသူ"
              action="create"
            />
            <PrivateRoute
              path="/dashboard/customer/:id/edit"
              component={EditCustomer}
              menu="ဝယ်ယူသူ"
              action="edit"
            />

            <PrivateRoute
              exact
              path="/dashboard/vehicle"
              component={Transport}
              menu="ပို့ဆောင်ရေး"
              action="read"
            />
            <PrivateRoute
              path="/dashboard/vehicle/create"
              component={CreateTransport}
              menu="ပို့ဆောင်ရေး"
              action="create"
            />
            <PrivateRoute
              path="/dashboard/vehicle/:id/edit"
              component={EditTransport}
              menu="ပို့ဆောင်ရေး"
              action="edit"
            />

            <PrivateRoute
              exact
              path="/dashboard/productCategory"
              component={ProductCategory}
              menu="ထုတ်ကုန်အမျိုးအစား"
              action="read"
            />
            <PrivateRoute
              path="/dashboard/productCategory/create"
              component={CreateProductCategory}
              menu="ထုတ်ကုန်အမျိုးအစား"
              action="create"
            />
            <PrivateRoute
              path="/dashboard/productCategory/:id/edit"
              component={EditProductCategory}
              menu="ထုတ်ကုန်အမျိုးအစား"
              action="edit"
            />

            <PrivateRoute
              exact
              path="/dashboard/productManagement"
              component={Product}
              menu="ထုတ်ကုန်စီမံခန့်ခွဲမှု"
              action="read"
            />
            <PrivateRoute
              path="/dashboard/productManagement/create"
              component={CreateProduct}
              menu="ထုတ်ကုန်စီမံခန့်ခွဲမှု"
              action="create"
            />
            <PrivateRoute
              path="/dashboard/productManagement/:id/edit"
              component={EditProduct}
              menu="ထုတ်ကုန်စီမံခန့်ခွဲမှု"
              action="edit"
            />

            <PrivateRoute
              exact
              path="/dashboard/unitManagement"
              component={Unit}
              menu="ယူနစ်စီမံခန့်ခွဲမှု"
              action="read"
            />
            <PrivateRoute
              path="/dashboard/unitManagement/create"
              component={CreateUnit}
              menu="ယူနစ်စီမံခန့်ခွဲမှု"
              action="create"
            />
            <PrivateRoute
              path="/dashboard/unitManagement/:id/edit"
              component={EditUnit}
              menu="ယူနစ်စီမံခန့်ခွဲမှု"
              action="edit"
            />

            <PrivateRoute
              exact
              path="/dashboard/userManagement"
              component={User}
              menu="အသုံးပြုသူ"
              action="read"
            />
            <PrivateRoute
              path="/dashboard/userManagement/create"
              component={CreateUser}
              menu="အသုံးပြုသူ"
              action="create"
            />
            <PrivateRoute
              path="/dashboard/userManagement/:id/edit"
              component={EditUser}
              menu="အသုံးပြုသူ"
              action="edit"
            />

            <PrivateRoute
              exact
              path="/dashboard/unitConversion"
              component={UnitConversion}
              menu="ယူနစ်ပြောင်းလဲခြင်း"
              action="read"
            />
            <PrivateRoute
              path="/dashboard/unitConversion/create"
              component={CreateUnitConversion}
              menu="ယူနစ်ပြောင်းလဲခြင်း"
              action="create"
            />
            <PrivateRoute
              path="/dashboard/unitConversion/:id/edit"
              component={EditUnitConversion}
              menu="ယူနစ်ပြောင်းလဲခြင်း"
              action="edit"
            />

            <PrivateRoute
              exact
              path="/dashboard/report"
              component={Report}
              menu="စာရင်းချုပ်"
              action="read"
            />
            <PrivateRoute
              exact
              path="/dashboard/report/wholesaleReportByDate"
              component={WholesaleReportByDate}
              menu="စာရင်းချုပ်"
              action="read"
            />
            <PrivateRoute
              exact
              path="/dashboard/report/wholesaleReportByYearly"
              component={WholesaleReportByYear}
              menu="စာရင်းချုပ်"
              action="read"
            />
            <PrivateRoute
              exact
              path="/dashboard/report/wholesaleReportByYearlyAndProduct"
              component={WholesaleReportByYearAndProduct}
              menu="စာရင်းချုပ်"
              action="read"
            />
            <PrivateRoute
              exact
              path="/dashboard/report/WholesaleReportByCNDate"
              component={WholesaleReportByCNDate}
              menu="စာရင်းချုပ်"
              action="read"
            />
            <PrivateRoute
              exact
              path="/dashboard/report/wholesaleReportByCNYear"
              component={WholesaleReportByCNYear}
              menu="စာရင်းချုပ်"
              action="read"
            />

            <PrivateRoute
              exact
              path="/dashboard/report/receivableReportByDate"
              component={ReceivableReportByDate}
              menu="စာရင်းချုပ်"
              action="read"
            />
            <PrivateRoute
              exact
              path="/dashboard/report/receivableReportByYear"
              component={ReceivableReportByYear}
              menu="စာရင်းချုပ်"
              action="read"
            />
            <PrivateRoute
              exact
              path="/dashboard/report/receivableReportByCustomer"
              component={ReceivableReportByCNDate}
              menu="စာရင်းချုပ်"
              action="read"
            />
            <PrivateRoute
              exact
              path="/dashboard/report/receivableReportByCity"
              component={ReceivableReportByDateAndCity}
              menu="စာရင်းချုပ်"
              action="read"
            />
            <PrivateRoute
              exact
              path="/dashboard/report/vehicleDetailReport"
              component={VehicleDetailReport}
              menu="စာရင်းချုပ်"
              action="read"
            />
            <PrivateRoute
              exact
              path="/dashboard/report/distributionReportByDate"
              component={DistributionReportByDate}
              menu="စာရင်းချုပ်"
              action="read"
            />
            <PrivateRoute
              exact
              path="/dashboard/report/distributionReportByYear"
              component={DistributionReportByYear}
              menu="စာရင်းချုပ်"
              action="read"
            />
          </Route>

          <Route>
            <div className="w-screen h-screen">
              <NotFound />
            </div>
          </Route>
        </Switch>
      </Router>

      {loading && <LoadingPopup />}
    </IntlProvider>
  );
}

export default App;
